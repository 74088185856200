<template>
  <header>
    <div class="max-1200">
      <img :src="require('@/assets/logo.svg')" />
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style lang="scss" scoped>
  header{
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 60px 30px;
    text-align: center;
    background-color: rgba(255,255,255,0.9);
    img{
      max-width: 100%;
    }
  }
</style>

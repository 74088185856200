<template>
  <footer class="background black">
    <div class="max-1200">
      &copy; Copyright Competitive Scorecard 2021. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
  footer{
    text-align: center;
    padding: 50px;
    color: #fff;
    font-size: 14px;
  }
</style>

<template>
  <div class="outer">
    <div class="content" :style="{ backgroundImage: `url('${thumbUrl(hero)}')` }">
      <h3>The University of Texas has released a national "Electric Competition Scorecard" in conjunction with the release of "The State of Electric Competition in the United States of America," an academic paper authored by Joshua D. Rhodes, PhD., Aaron Nisman, William Wade, and Michael E. Webber, PhD. from the Webber Energy Group at the University of Texas.</h3>
      <h2>What They’re Saying:</h2>
      <p><i>"Competition is paramount to forging a cleaner energy future. Unfortunately, it is difficult to capture how states enforce the policies, laws, and regulations that lead to higher scores. CEN is working hard to ensure that all ratepayers can benefit from an electric market that is truly competitive in practice, not just on paper."</i><br><b> - Mark Pischea, Conservative Energy Network President & CEO</b></p>
      <p><i>"This study seeks to assess the existing principles of competition in each state’s electricity sectors, it is not a commentary on former or current public officials. Competition is often introduced into systems to nudge them towards higher levels of efficiency."</i><br><b> - Josh Rhodes, PhD Webber Energy Group</b></p>
      <div class="text-center">
        <v-btn href="/downloads/UT-Competition-Study.pdf" x-large dark color="#9D0000" class="ma-5">Read the Full Study Here</v-btn>
        <v-btn href="/downloads/UT-Executive-Summary.pdf" x-large dark color="#BF5701" class="ma-5">Executive Summary and Scorecard</v-btn>
      </div>
    </div>
    <v-map></v-map>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      hero: 'assets/circuits.png',
    }
  },
  methods:{
    thumbUrl(photo) {
      return require("@/" + photo);
    },
  },
}
</script>

<style lang="scss" scoped>
  .outer{
    position: relative;
    padding-left: 150px;
    @media (max-width: 600px) {
      padding: 20px;
    }
    svg{
      display: block;
      width: calc(100% + 1.3px);
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    &:before{
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      width: 10%;
      max-width: 150px;
      background-color: #4E9AA9;
      z-index: -1;
      @media (max-width: 600px) {
        display: none;
      }
    }
    .content{
      max-width: 1200px;
      margin: auto;
      padding: 50px 50px 50px 0;
      background:{
        position: right 170%;
        size: 20%;
        repeat: no-repeat;
      }
      @media (max-width: 600px) {
        padding: 0;
      }
      h2{
        font-size: 40px;
        color: #9D0000;
        line-height: 50px;
        margin-bottom: 50px;
        text-transform: uppercase;
        text-align: center;
      }
      h3{
        font-size: 24px;
        color: #2D2D2D;
        line-height: 34px;
        margin-bottom: 50px;
      }
      p{
        margin-bottom: 20px;
      }
    } 
  }
</style>

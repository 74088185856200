<template>
  <div>
    <v-header></v-header>
    <v-hero></v-hero>
    <router-view></router-view>
    <v-footer></v-footer>
  </div>
</template>

<script>
  export default {
    name: 'Standard',
    data () {
      return {

      }
    },
  };
</script>

<style lang="scss">
  .standard{

  }
</style>
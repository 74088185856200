<template>
  <div class="map">
    <iframe width="900" height="700" src="/map/"></iframe>
    <div class="webber-logo">
      <img :src="require('@/assets/webber-logo.png')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Map',
}
</script>

<style lang="scss" scoped>
  .map{
    text-align: center;
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    @media (max-width: 600px) {
      display: none;
    }
    iframe{
      border: none;
    }
    .webber-logo{
      max-width: 260px;
      margin: auto;
      img{
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
  }
</style>

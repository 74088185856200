import Vue from 'vue';

import Header                 from './Header.vue';
import Hero                   from './Hero.vue';
import USMap                  from './Map.vue';
import Footer                 from './Footer.vue';

Vue.component('v-header',     Header);
Vue.component('v-hero',       Hero);
Vue.component('v-footer',     Footer);
Vue.component('v-map',        USMap);

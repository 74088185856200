<template>
  <div class="hero" ref="hero" :style="{ backgroundImage: `url('${thumbUrl(hero)}')` }">
    <v-container fluid>
      <div class="shape ruby"></div>
      <div class="shape dirty-jeans"></div>
      <div class="shape pumpkin"></div>
      <v-row class="max-1200" dense>
        <v-col md="8" align-self="center">
          <h1>The State<br>of Electric<br>Competition<br>in the United States</h1>
        </v-col>
        <v-col md="4" align-self="center">
          <div class="cta">
            <h3>View Our Webinar</h3>
            <v-btn @click="dialog = !dialog" x-large light color="#ffffff">Click Here</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="dialog"
      width="80%"
      max-width="1000"
    >
      <video controls>
        <source src="/webinar.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Hero',
  data () {
    return {
      dialog: false,
      playing: false,
      hero: 'assets/hero-image.jpg',
    }
  },
  methods:{
    thumbUrl(photo) {
      return require("@/" + photo);
    },
  },
  mounted(){

    let doc = document.documentElement;
    if(/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
      doc = document.body;
    }

    window.onscroll = function() {
      let docHeight = document.body.offsetHeight;
      let winHeight = window.innerHeight;
      let scrollPercent = doc.scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercent * 100);
      let scrollReversed = Math.abs(scrollPercentRounded-100)*0.6;

      document.querySelectorAll('.hero')[0].style.backgroundPosition = "center " + scrollReversed+"%";
    }

  }
}
</script>

<style lang="scss" scoped>
  .hero{
    transition: all 0.5s ease-in-out;
    position: relative;
    background:{
      position: center 100%;
      size: cover;
    }
    height: 700px;
    padding: 180px 0 0 0;
    overflow: hidden;
    @media (max-width: 600px) {
      height: auto;
    }
    .v-dialog{
      background-color: black;
    }
    .svg-top{
      display: block;
      width: calc(100% + 1.3px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-150px) rotate(180deg);
      z-index: 0;
    }
    .svg-bottom{
      display: block;
      width: calc(100% + 1.3px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-150px) rotate(180deg);
      z-index: 0;
    }
    &:before{
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      z-index: 0;
      background: rgb(0,0,0);
      background: -moz-linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 43%);
      background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 43%);
      background: linear-gradient(45deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 43%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    .container{
      position: relative;
      height: 532px;
      padding: 0px;
      @media (max-width: 600px) {
        height: auto;
      }
      .shape{
        transition: all 0.2s ease-in-out;
        @media (max-width: 600px) {
          display: none;
        }
        &.dirty-jeans{
          position: absolute;
          transform: skew(-15deg, -15deg);
          width: 100vw;
          height: 100vh;
          background-color: rgba(#4E9AA9,0.3);
          bottom: 100%;
          left: 50%;
        }
        &.ruby{
          content: '';
          position: absolute;
          transform: skew(6deg, 15deg);
          width: 100vw;
          height: 50vh;
          background-color: rgba(#9D0000,0.3);
          top: 108%;
        }
        &.pumpkin{
          content: '';
          position: absolute;
          transform: skew(41deg, 13deg);
          width: 40vw;
          height: 60vh;
          background-color: rgba(#BF5701,0.3);
          top: 78%;
          left: 82%;
        }
      }
      h1{
        font:{
          family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          size: 70px;
          weight: 500;
        }
        color: #fff;
        letter-spacing: -1px;
        line-height: 70px;
        text-shadow: 4px 3px 0 #000000;
        @media (max-width: 600px) {
          font-size: 40px;
          line-height: 50px;
        }
      }
      .cta{
        background-color: rgba(0,0,0,0.8);
        padding: 30px 40px;
        border-radius: 10px;
        text-align: center;
        h3{
          font:{
            family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            size: 25px;
            weight: 500;
          }
          color: #fff;
          line-height: 35px;
          text-transform: uppercase;
          span{
            color: #4E9AA9;
          }
        }
      }
      .row{
        height: 532px;
        position: relative;
        z-index: 1;
        @media (max-width: 600px) {
          height: auto;
        }
        .col{
          padding: 0px;
          .max-1200{
            height: 532px;
            display: flex;
            align-items: center;
            @media (max-width: 600px) {
              padding: 20px;
              height: auto;
            }
          }
        }
      }
    }
  }
</style>

import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export

Vue.config.productionTip = false;

import router from './router';
import './components';

new Vue({
  vuetify,
  router,
  data:{
    general:{
      www: 'domain.com',
      name: "Competition Scorecard",
      slogan: "It's not the real thing but super fresh!",
    },
  },
  render: h => h(App),
}).$mount('#app')

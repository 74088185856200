import Vue        from 'vue'
import VueRouter  from 'vue-router'
import VueMeta    from 'vue-meta'

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'head',
  refreshOnceOnNavigation: true
});

const routes = [
  { 
    path: '/',
    component: () => import('@/templates/Standard.vue'),
    children: [
      {
        name: 'Home',
        path: '/',
        component: () => import('@/views/Home.vue'),
        meta:{
          mode: 'standard',
          title: 'Home',
          keywords: [''],
          description: '',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

const titles =    [ 'subject', 'og:title', 'og:site_name', 'og:image:alt', 'twitter:title' ];
const urls =      [ 'url', 'identifier-URL', 'og:url', 'twitter:url' ];
const images =    [ 'og:image', 'twitter:image' ];

function setMeta(metaName, metaValue) {
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (
      metas[i].getAttribute('name') === metaName || 
      metas[i].getAttribute('property') === metaName
    ) {
      metas[i].setAttribute("content", metaValue);
    }
  }
}

router.beforeEach(async (to, from, next) => {
  await Vue.nextTick();

  let title = to.meta.title;
  let url = router.app.general.www + to.path;
  document.title = title;

  for (var i = 0; i < titles.length; i++) {
    setMeta(titles[i],title);
  }
  for (i = 0; i < urls.length; i++) {
    setMeta(urls[i],url);
  }
  for (i = 0; i < images.length; i++) {
    if(to.meta.image){
      setMeta(images[i],to.meta.image);
    }
  }
  next();
});

export default router

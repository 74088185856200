<template>
  <v-app id="app">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">

body{
  background-color: #F6F2EF;
  margin:  0;
  h1,h2,h3,h4,h5,h6,p{
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  h1,h2,h3,h4,h5,h6{
    margin-bottom: 10px;
  }
  p{
    line-height: 1.4em;
    font-size: 18px;
  }
}

.v-application {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: transparent !important;
  .max-1200{
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    @media (max-width: 600px) {
      width: auto;
      padding: 0 10px;
    }
  }
  .background{
    &.ruby{
      background-color: #9D0000;
    }
    &.pumpkin{
      background-color: #BF5701;
    }
    &.kacki{
      background-color: #A97D51;
    }
    &.blue-jeans{
      background-color: #2F4858;
    }
    &.teal{
      background-color: #4E9AA9;
    }
    &.sky{
      background-color: #7EC3EE;
    }
    &.light-gray{
      background-color: #E3E3E3;
    }
    &.medium-gray{
      background-color: #898989;
    }
    &.dark-gray{
      background-color: #2D2D2D;
    }
    &.black{
      background-color: #000000;
    }
  }

}
</style>
